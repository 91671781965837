@media print {
    .print\:pagebreak-after {
        clear: both;
        page-break-after: always;
    }

    .print\:pagebreak-avoid {
        page-break-inside: avoid;
    }
}

@page {
    size: A4;
    margin: 0;
}